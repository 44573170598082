/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import mbpUtil from 'mbp-api-util';
import mbpLogger from 'mbp-logger';

import { bool, func } from 'prop-types';
import { actions as tagManagerActions } from '../../../../state/ducks/TagManager';
import { parseJwt } from '../../../../state/ducks/Member/ducks/Auth/helper/helper';
import { getIsAuthenticatedStatus } from '../../../../state/ducks/Member/ducks/Common/Common-Selectors';

import { useAuth } from '../../../../state/ducks/Member/ducks/Auth/Plugin/auth';
import withAsyncScript from '../../../../lib/scriptLoader';

const ChromeSignin = ({
    trackEvent,
    authenticated,
    isScriptLoadSucceed,
}) => {
    const { isReady, loginByRedirect } = useAuth();

    const handleResponse = () => {
        if (window.location.href.indexOf('/auth/callback') < 0) {
            const handleCredentialResponse = async (response) => {
                if (response !== null && response.credential !== null) {
                    trackEvent({
                        eventCategory: 'Sign In',
                        eventAction: 'Login',
                        eventLabel: 'Logon_From_ChromeBrowser|<<pageType>>',
                    });

                    const userCreds = parseJwt(response.credential); // account info json

                    if (userCreds && userCreds.email) {
                        try {
                            loginByRedirect({ connection: 'google-oauth2' });
                        } catch (err) {
                            mbpLogger.logError({
                                function: 'handleResponse',
                                module: 'ChromeSignin.js',
                                message: 'Chrome Sigin failed',
                            });
                        }
                    }
                }
            };

            if (typeof window !== 'undefined' && window?.google?.accounts?.id) {
                const clientID = mbpUtil.getEnv('APP_CHROME_CLIENT_ID') ? mbpUtil.getEnv('APP_CHROME_CLIENT_ID') : '1063797252869-6aqf0rm4rhvq8nrq06lsp2j3k015irnh.apps.googleusercontent.com';

                window.google.accounts.id.initialize({
                    client_id: clientID,
                    callback: handleCredentialResponse,
                });

                window.google.accounts.id.prompt();
            }
        }
    };

    if (!isScriptLoadSucceed || !isReady) {
        return null;
    }

    return (
        <>
            {(!authenticated && isReady) && handleResponse()}
        </>
    );
};

const CHROME_URL = mbpUtil.getEnv('APP_CHROME_SCRIPT') ? mbpUtil.getEnv('APP_CHROME_SCRIPT') : 'https://accounts.google.com/gsi/client';

const mapStateToProps = (state) => ({
    authenticated: getIsAuthenticatedStatus(state),
});

const mapDispatcherToProps = (dispatch) => ({
    trackEvent: bindActionCreators(tagManagerActions.trackEvent, dispatch),
});

ChromeSignin.propTypes = {
    authenticated: bool.isRequired,
    isScriptLoadSucceed: bool.isRequired,
    trackEvent: func.isRequired,
};

export default connect(
    mapStateToProps, mapDispatcherToProps,
)(withAsyncScript(CHROME_URL)(ChromeSignin));
